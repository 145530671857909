<template>
  <div>
    <mc-form-input
      v-if="element.type === 'textarea'"
      :icon="['fad', 'file-contract']"
      type="wysiwyg"
      :label="$t('designer.Content')"
      v-model="element.content"
      :placeholder="$t('designer.EnterContent')"
      :description="$t('designer.TextareaDescription')"
      buttons="few"
    >
      <template v-slot:extraButtons>
        <b-button-group size="sm" class="my-2 ml-2 float-right">
          <McButton :icon="['far', 'align-left']" @click="setAlign('left')" :variant="element.textAlign === 'left' ? 'primary' : 'outline-secondary'" />
          <McButton :icon="['far', 'align-center']" @click="setAlign('center')" :variant="element.textAlign === 'center' ? 'primary' : 'outline-secondary'" />
          <McButton :icon="['far', 'align-right']" @click="setAlign('right')" :variant="element.textAlign === 'right' ? 'primary' : 'outline-secondary'" />
          <McButton :icon="['far', 'align-justify']" @click="setAlign('justify')" :variant="element.textAlign === 'justify' ? 'primary' : 'outline-secondary'" />
        </b-button-group>
      </template>
    </mc-form-input>
    <mc-form-select
      v-if="element.type === 'textarea'"
      v-model="element.fromMetaIfExists"
      :label="$t('designer.DynamicData')"
      :options="[
        { label: $t('designer.NotDynamic'), value: '' },
        { label: $t('designer.PersonalMessage'), value: 'personalMessage' },
        { label: $t('designer.GiftcardDynamic1'), value: 'giftcardDynamic1' },
        { label: $t('designer.GiftcardDynamic2'), value: 'giftcardDynamic2' },
      ]"
      :icon="['fas', 'database']"
      :description="$t('designer.DynamicDataDescription')"
    />
    <template v-if="element.type === 'valexp'">
      <mc-form-checkboxes
        v-if="element.type === 'valexp'"
        v-model="element.layout"
        :label="$t('designer.Layout')"
        :options="[
          { label: $t('designer.Layout-1'), value: 'layout-1' },
          { label: $t('designer.Layout-2'), value: 'layout-2' },
          { label: $t('designer.Layout-3'), value: 'layout-3' },
          { label: $t('designer.Layout-4'), value: 'layout-4' },
          { label: $t('designer.Layout-5'), value: 'layout-5' },
        ]"
        :icon="['fas', 'pencil-ruler']"
      />
      <mc-form-checkboxes
        v-if="['layout-2', 'layout-4', 'layout-5'].includes(element.layout)"
        v-model="element.fontSizes"
        :label="$t('designer.FontSizes')"
        :options="[
          { label: $t('designer.EmphasizeValue'), value: 'multiple' },
          { label: $t('designer.EqualSizes'), value: 'one' },
        ]"
        :icon="['fad', 'text-size']"
      />
      <div class="my-2 text-center" v-if="element.layout == 'layout-2'">
        <b-button-group size="sm" class="mx-auto">
          <McButton :icon="['far', 'align-left']" @click="setAlign('left')" :variant="element.textAlign === 'left' ? 'primary' : 'outline-secondary'" />
          <McButton :icon="['far', 'align-center']" @click="setAlign('center')" :variant="element.textAlign === 'center' ? 'primary' : 'outline-secondary'" />
          <McButton :icon="['far', 'align-right']" @click="setAlign('right')" :variant="element.textAlign === 'right' ? 'primary' : 'outline-secondary'" />
          <McButton :icon="['far', 'align-justify']" @click="setAlign('justify')" :variant="element.textAlign === 'justify' ? 'primary' : 'outline-secondary'" />
        </b-button-group>
      </div>
      <mc-form-checkboxes v-if="layoutOptions.length > 0" v-model="element.layoutOptions" :label="$t('designer.Elements')" :options="layoutOptions" :icon="['fad', 'clipboard-list-check']" multiple />
      <mc-form-input :icon="['fas', 'ruler']" type="number" :label="$t('designer.SeparatorWidth')" min="0" max="5" step="0.01" v-model="element.separatorWidth" unit="mm" v-if="showSeparatorWidth" />
      <!--pre>{{ element }}</pre-->
    </template>

    <mc-form-select v-model="element.fontCategory" :label="$t('designer.FontCategory')" :options="fontCategoryList" icon="folder-font" />
    <mc-form-input :icon="['fad', 'font']" type="font" :label="$t('designer.Font')" :fontCategories="element.fontCategory" @fontVariants="handleNewFont" v-model="element.font" />
    <mc-form-input :icon="['fad', 'text-size']" type="number" :label="$t('designer.FontSize')" min="5" max="300" v-model="element.fontSize" v-if="element.type === 'textarea'" />
    <template v-if="element.type === 'textarea'">
      <template v-if="fontWeights.length > 2">
        <mc-form-select v-model="element.fontWeight" :label="$t('designer.FontWeight')" :options="fontWeightsNormal" icon="font-weight" />
        <mc-form-select v-model="element.fontWeightBold" :label="$t('designer.FontWeightBold')" :options="fontWeightsBold" icon="font-weight-bold" />
      </template>
      <template v-else-if="fontWeights.length < 2">
        <div class="alert alert-warning" v-html="$t(fontHasItalic ? 'designer.FontDoesNotSupportBold' : 'designer.FontDoesNotSupportBoldOrItalic')" />
      </template>
      <template v-if="fontWeights.length > 1 && !fontHasItalic">
        <div class="alert alert-warning" v-html="$t('designer.FontDoesNotSupportItalic')" />
      </template>
    </template>
    <template v-else-if="fontWeights.length > 1">
      <mc-form-select v-model="element.fontWeight" :label="$t('designer.FontWeight')" :options="fontWeightsAll" icon="font-weight" />
    </template>
    <mc-form-input name="backgroundBlur" type="range" :label="$t('designer.FontSpacing')" min="-0.5" max="2" step=".05" v-model="element.fontSpacing">
      <template slot="append">
        <b-input-group-text style="min-width: 80px;text-align: center !important">{{ element.fontSpacing }}</b-input-group-text>
      </template>
    </mc-form-input>
    <mc-form-color-input :label="$t('designer.ForegroundColor')" v-model="element.forecolor" format="hex" opacity />
    <mc-form-checkbox :label="$t('designer.Background')" v-model="element.useBackground">{{ $t('designer.BackgroundColor') }}</mc-form-checkbox>
    <mc-form-color-input v-if="element.useBackground" :description="$t('designer.ChooseColorAndOpacity')" v-model="element.backcolor" format="hex" opacity />
    <McFormInput v-if="element.useBackground" type="number" size="sm" unit="mm" :min="0" :max="100" :step="1 / pixelsPerMm" :label="$t('designer.Padding')" v-model="element.padding" />
    <!--pre>
      {{ element }}
    </pre-->
    <mc-form-input v-if="element.useBackground" type="range" :label="$t('designer.CornerRounding')" min="0" max="100" step="1" v-model="element.cornerRounding">
      <template slot="append">
        <b-input-group-text style="min-width: 80px;text-align: center !important">{{ element.cornerRounding }}%</b-input-group-text>
      </template>
    </mc-form-input>
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    pixelsPerMm: Number,
  },
  data() {
    return {
      fontVariants: {},
      fontCategoryList: [
        { value: 'all', label: this.$t('designer.FontCategories.ShowAll') },
        { value: 'serif', label: this.$t('designer.FontCategories.Serif') },
        { value: 'sans-serif', label: this.$t('designer.FontCategories.SansSerif') },
        { value: 'handwriting', label: this.$t('designer.FontCategories.Handwriting') },
        { value: 'display', label: this.$t('designer.FontCategories.Display') },
        { value: 'monospace', label: this.$t('designer.FontCategories.MonoSpace') },
      ],
    }
  },
  watch: {
    'element.layout'() {
      this.element.layoutOptions = ['value', 'code', 'expiry', 'codeAndExpiry', 'credit', 'separator']
      let newPosition = {...this.element.position}
      let aspect = 0.3
      if (this.element.layout == 'layout-2') {
        aspect = 0.4
      } else if (this.element.layout == 'layout-4') {
        aspect = 0.04
      } else if (this.element.layout == 'layout-5') {
        aspect = 0.1
      }
      newPosition.height = (newPosition.width - this.effectivePadding * 2) * aspect + this.effectivePadding * 2
      this.$emit('moved', newPosition)
    },
  },
  computed: {
    layoutOptions() {
      let options = []
      if (['layout-1', 'layout-2'].includes(this.element.layout)) {
        options.push({ label: this.$t('designer.CodeValue'), value: 'value' })
      }
      if (['layout-1'].includes(this.element.layout)) {
        options.push({ label: this.$t('designer.CodeCodeAndExpiry'), value: 'codeAndExpiry' })
      } else if (['layout-2'].includes(this.element.layout)) {
        options.push({ label: this.$t('designer.CodeCode'), value: 'code' })
        options.push({ label: this.$t('designer.CodeExpiry'), value: 'expiry' })
      }
      if (['layout-1', 'layout-2', 'layout-3'].includes(this.element.layout)) {
        options.push({ label: this.$t('designer.CodeCredit'), value: 'credit' })
      }

      let shownElms = 0

      for (var i in options) {
        if (this.element.layoutOptions.includes(options[i].value)) {
          shownElms++
        }
      }

      if (this.element.layout === 'layout-1' && shownElms > 1) {
        options.push({ label: this.$t('designer.CodeSeparator'), value: 'separator' })
      } else if (['layout-2'].includes(this.element.layout) && shownElms > 1 && this.element.layoutOptions.includes('credit')) {
        options.push({ label: this.$t('designer.CodeSeparator'), value: 'separator' })
      } else if (['layout-3'].includes(this.element.layout) && this.element.layoutOptions.includes('credit')) {
        options.push({ label: this.$t('designer.CodeSeparator'), value: 'separator' })
      }

      return options
    },
    showSeparatorWidth() {
      if (!this.element.layoutOptions.includes('separator')) {
        return false
      }
      for (let i in this.layoutOptions) {
        if (this.layoutOptions[i].value === 'separator') {
          return true
        }
      }
      return false
    },
    fontHasItalic() {
      for (var i in this.fontVariants) {
        if (this.fontVariants[i].italic) {
          return true
        }
      }
      return false
    },
    fontWeights() {
      let ret = []
      for (var i in this.fontVariants) {
        if (ret.includes(parseInt(this.fontVariants[i].weight))) {
          continue
        }
        ret.push(parseInt(this.fontVariants[i].weight))
      }
      return ret
    },
    fontWeightsNormal() {
      return this.fontWeights.slice(0, -1).map(a => {
        return { value: a, label: a, $isDisabled: a >= this.element.fontWeightBold }
      })
    },
    fontWeightsBold() {
      return this.fontWeights.slice(1).map(a => {
        return { value: a, label: a, $isDisabled: a <= this.element.fontWeight }
      })
    },
    fontWeightsAll() {
      return this.fontWeights.map(a => {
        return { value: a, label: a, $isDisabled: false }
      })
    },
    effectivePadding() {
      return this.element.useBackground ? this.element.padding : 0
    },
  },
  methods: {
    setAlign(newAlign) {
      this.element.textAlign = newAlign
    },
    handleNewFont(a) {
      this.fontVariants = a.variants
      if (this.fontWeights.length == 1) {
        this.element.fontWeight = this.fontWeights[0]
        this.element.fontWeightBold = this.fontWeights[0]
      } else if (this.fontWeights.length == 2) {
        this.element.fontWeight = this.fontWeights[0]
        this.element.fontWeightBold = this.fontWeights[1]
      } else {
        if (this.fontWeights.includes(this.element.fontWeight) && this.fontWeights.includes(this.element.fontWeightBold)) {
          // We're goooood :)
        } else if (this.fontWeights.includes(400) && this.fontWeights.includes(700)) {
          this.element.fontWeight = 400
          this.element.fontWeightBold = 700
        } else if (this.fontWeights.includes(400) && Math.max(...this.fontWeights) > 400) {
          this.element.fontWeight = 400
          this.element.fontWeightBold = Math.max(...this.fontWeights)
        } else {
          this.element.fontWeight = this.fontWeights[0]
          this.element.fontWeightBold = this.fontWeights[2]
        }
      }
    },
  },
}
</script>
