export const jsEmbedCode = function (merchantId, base) {
  var lines = [
    `<script>!function(d,t,e){d.dgk||((e=t.createElement("script")).async=1,`,
    `e.src="https://api.danskegavekort.dk/integration/v2/js/loader.js",`,
    `t.body.appendChild(e),d.dgk=e)}(window,document)</sc` + `ript>`
  ]
  if (base) {
    lines.unshift(`<div data-dgk-merchant="${merchantId}" data-dgk-base="${base}"></div>`)
  } else {
    lines.unshift(`<div data-dgk-merchant="${merchantId}"></div>`)
  }
  return lines.join('\n')
}
export const wpEmbedCode = function (base) {
  if (base) {
    return `[danskegavekort base="${base}"]`
  }
  return `[danskegavekort]`
}
