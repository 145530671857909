<template>
  <!-- eslint-disable-next-line -->
  <b-card no-body class="p-1 m-1 designer-list-element">
    <p class="element-type-label">{{ $t('designer.elementTypes.' + element.type) }}</p>
    <div class="d-flex flex justify-content-between">
      <p class="m-2">{{ element.label }}</p>
      <b-button-group size="sm" class="ml-2 m-1">
        <McButton icon="edit" :disabled="!isCurrentPage" :title="$t('designer.EditElement')" @click="e => $emit('select', e)" variant="primary" />
        <McButton icon="clone" :disabled="!isCurrentPage" :title="$t('designer.CloneElement')" @click="e => $emit('clone', e)" variant="info" />
        <McButton icon="trash" :disabled="!isCurrentPage" :title="$t('designer.DeleteElement')" @click="e => $emit('delete', e)" variant="danger" />
      </b-button-group>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    element: Object,
    isCurrentPage: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {},
  watch: {},
}
</script>
<style lang="scss">
.designer-list-element {
  cursor: move;
  transition: box-shadow 0.1s;
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  .element-type-label {
    position: absolute;
    top: 3px;
    left: 3px;
    font-size: 10px;
  }
}
</style>
