<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    submiticon="plus"
    :title="$t('offlineGiftcards.FinalizeOfflineGiftcard')"
    :submittext="$t('general.Create')"
  >
    <div v-if="selectedCard">
      <p>
        <small>{{ $t('offlineGiftcards.GiftcardType') }}</small>
        <br />&nbsp;&nbsp;<strong>{{ selectedCard.giftcardHeadline }}</strong>
      </p>
      <p>
        <small>{{ $t('offlineGiftcards.Code') }}</small>
        <br />&nbsp;&nbsp;<strong>{{ selectedCard.code }}</strong>
      </p>
      <p v-if="selectedCard.priceInCents != 0">
        <small>{{ $t('offlineGiftcards.Amount') }}</small>
        <br />&nbsp;&nbsp;<strong>{{ selectedCard.priceInCents | niceCurrency }}</strong>
      </p>
      <mc-form-input
        v-else
        name="amount"
        type="number"
        :min="selectedCard.minFreeAmountInCents / 100"
        :max="selectedCard.maxFreeAmountInCents / 100"
        step="100"
        v-model="selectedPrice"
        v-validate="'required|decimal:2|min_value:' + (selectedCard.minFreeAmountInCents / 100) + '|max_value:' + (selectedCard.maxFreeAmountInCents / 100)"
        :data-vv-as="$t('orders.Amount')"
        :label="$t('orders.Amount')"
        :placeholder="$t('orders.ChooseGiftcardAmount')"
        key="orderform.amount"
        :errorHint="errors.first('amount')"
        :state="!errors.has('amount')"
        :icon="['fad', 'money-bill-wave']"
        :unit="$t('general.DKK')"
      />

      <p v-if="selectedCard.dateExpires != '0000-00-00 00:00:00'">
        <small>{{ $t('general.Expiry') }}</small>
        <br />&nbsp;&nbsp;<strong>{{ selectedCard.dateExpires | formatDate }}</strong>
      </p>
      <mcFormDatePicker
        v-else
        :label="$t('general.Expiry')"
        :icon="['fas', 'calendar-check']"
        v-model="curData.dateExpires"
      />

      <mc-form-checkbox v-model="addCustomerInfo" :description="$t('offlineGiftcards.CustomerInfoDescription')">{{ $t('offlineGiftcards.AddCustomerInfo') }}</mc-form-checkbox>

      <div v-if="addCustomerInfo">
        <mc-form-input
          name="name"
          type="text"
          v-model="curData.customer.name"
          :label="$t('general.NameParensOptional')"
          :placeholder="$t('general.EnterName')"
          key="orderform.name"
          :errorHint="errors.first('name')"
          :state="!errors.has('name')"
          icon="signature"
        />

        <mc-form-input
          name="email"
          type="email"
          v-model="curData.customer.email"
          v-validate="'email'"
          :data-vv-as="$t('general.Email')"
          :label="$t('general.EmailParensOptional')"
          :placeholder="$t('general.EnterEmail')"
          key="orderform.email"
          :errorHint="errors.first('email')"
          :state="!errors.has('email')"
          icon="at"
        />

        <mc-form-input
          name="phone"
          type="text"
          v-model="curData.customer.phone"
          v-validate="'numeric'"
          :data-vv-as="$t('general.Phone')"
          :label="$t('general.PhoneParensOptional')"
          :placeholder="$t('general.EnterPhone')"
          key="orderform.phone"
          :errorHint="errors.first('phone')"
          :state="!errors.has('phone')"
          icon="phone"
        />
      </div>
      <mc-form-checkbox name="acceptTerms" v-model="acceptTerms" :description="$t('orders.CommissionDescription')">{{ $t('orders.acceptTerms') }}</mc-form-checkbox>
      <!--pre>{{ selectedCard }}, {{ selectedPrice }}</pre-->
    </div>
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { niceCurrency } from '@/helpers/Formatting'
import moment from 'moment'
import OfflineGiftcardsService from '@/services/OfflineGiftcardsService'

const defaultData = {
  priceInCents: 40000,
  dateExpires: moment().add(10, 'days').format('YYYY-MM-DD'),
  customer: {
    name: '',
    email: '',
    phone: '',
  },
}

export default {
  data() {
    return {
      curData: { ...defaultData },
      addCustomerInfo: false,
      acceptTerms: false,
      selectedPrice: 0,
    }
  },
  props: {
    selectedCard: Object,
  },
  mounted() {
    this.curData = { ...defaultData }
  },
  watch: {
    selectedPrice() {
      this.curData.priceInCents = Math.round(this.selectedPrice * 100)
    },
    selectedCard() {
      this.forceReset()
      if (this.selectedCard.priceInCents) {
        this.curData.priceInCents = this.selectedCard.priceInCents
        this.selectedPrice = this.selectedCard.priceInCents / 100
      } else {
        this.curData.priceInCents = this.selectedCard.defFreeAmountInCents
        this.selectedPrice = 0
      }
      if (this.selectedCard.validity == -1) {
        this.curData.dateExpires = moment(this.selectedCard.staticValidity).format('YYYY-MM-DD')
      } else {
        this.curData.dateExpires = moment().add(this.selectedCard.validity, 'days').format('YYYY-MM-DD')
      }
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    forceReset() {
      this.$refs['mcFormInstance'].forceReset()
    },

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      if (!this.acceptTerms) {
        return Promise.reject(this.$t('orders.YouNeedToAcceptTheTerms'))
      }
      return OfflineGiftcardsService.finalize(this.selectedCard.id, this.curData).then(
        data => {
          this.$emit('orderCreated', data)
          this.toastSuccess(this.$t('offlineGiftcards.GiftcardFinalized'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating order', error)
          return Promise.reject(error)
        },
      )
    },
  },
  filters: {
    niceCurrency(amountInCents) {
      return niceCurrency(amountInCents)
    },
    formatDate(date) {
      return moment(date).format('ll')
    },
  },
}
</script>
