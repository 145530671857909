<template>
  <b-form-group :label="label" :description="description" :state="!errorHint">
    <b-input-group :size="$attrs['size']">
      <template v-if="$slots.prepend" slot="prepend">
        <slot name="prepend" />
      </template>
      <b-input-group-text v-else-if="icon" slot="prepend" class="bg-primary text-white">
        <McIcon :icon="icon" />
      </b-input-group-text>
      <!-- prettier-ignore -->
      <component
        :is="componentType"
        v-bind="$attrs"
        :class="'form-control' + (rightalign ? ' text-right' : '')"
        v-on="listeners"
        :local-fonts="[
          {name:'Bickley Script',sane:'bickley_script',variants:['0,400']},
          {name:'General Sans',sane:'general_sans',variants:['0,200', '1,200', '0,300', '1,300', '0,400', '1,400', '0,500', '1,500', '0,600', '1,600', '0,700', '1,700']}
        ]"
        >

        <slot name="extraButtons" slot="extraButtons"></slot>
      </component>
      <template v-if="unit" slot="append">
        <b-input-group-text>{{ unit }}</b-input-group-text>
      </template>
      <template v-if="$slots.append" slot="append">
        <slot name="append" />
      </template>
    </b-input-group>
    <TransitionToAuto v-if="errorHint">
      <div class="invalid-feedback d-block">{{ errorHint }}</div>
    </TransitionToAuto>
  </b-form-group>
</template>

<script>
export default {
  computed: {
    listeners() {
      return {
        ...this.$listeners,
      }
    },
    componentType() {
      if (this.$attrs.type === 'font') {
        return 'mc-fontpicker'
      }
      if (this.$attrs.type === 'textarea') {
        return 'b-textarea'
      }
      if (this.$attrs.type === 'wysiwyg') {
        return 'wysiwyg'
      }
      return 'b-form-input'
    },
  },
  props: {
    icon: [String, Array],
    unit: String,
    prepend: String,
    errorHint: String,
    label: String,
    description: String,
    rightalign: Boolean,
  },
}
</script>

<style>
.form-control.mcfontpicker {
  padding: 0;
  border: 1px solid #ced4da;
}
</style>
