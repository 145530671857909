<template>
  <!--pre>Image: {{ element }}</pre-->
  <div>
    <mc-form-color-input :description="$t('designer.ChooseColorAndOpacity')" :label="$t('designer.Color')" v-model="element.backcolor" format="hex" opacity />
    <mc-form-checkboxes
      v-model="element.shape"
      :label="$t('designer.Shape')"
      :options="[
        { label: $t('designer.Rectangle'), value: 'rectangle' },
        { label: $t('designer.Ellipse'), value: 'ellipse' },
      ]"
      :icon="['fad', 'user-tag']"
    />
    <mc-form-input v-if="element.shape == 'rectangle'" type="range" :label="$t('designer.CornerRounding')" min="0" max="100" step="1" v-model="element.cornerRounding">
      <template slot="append">
        <b-input-group-text style="min-width: 80px;text-align: center !important">{{ element.cornerRounding }}%</b-input-group-text>
      </template>
    </mc-form-input>
  </div>
</template>

<script>
export default {
  props: {
    element: Object,
    pixelsPerMm: Number,
  },
}
</script>
