<template>
  <transitionToAuto v-if="LayoutStore.footer">
    <footer id="footer_wrap" class="footer navbar-static-bottom bg-tertiary py-5 text-muted">
      <div class="container">
        <div class="row">
          <div class="col-12 col-sm-9 text-center text-sm-left">
            <h4>{{ $t('footer.Contact') }}</h4>
            <p>{{ $t('footer.IfYouNeedHelp') }}</p>
            <ul style="list-style: none; margin: 0; padding: 0 0 0 0.5em">
              <li>
                <a href="tel:+4559551111"><mc-icon icon="phone" />&nbsp;&nbsp;+45&nbsp;59&nbsp;55&nbsp;11&nbsp;11</a>
              </li>
              <li>
                <a href="mailto:info@danskegavekort.dk"><mc-icon icon="envelope" />&nbsp;&nbsp;info@danskegavekort.dk</a>
              </li>
            </ul>
          </div>
          <div class="col-12 col-sm-3 text-center text-sm-right mt-3">
            <router-link to="/"><img class="footer_logo" src="../assets/DanskeGavekort-logo-white.png" width="600" height="150" /></router-link>
          </div>
        </div>
      </div>
    </footer>
  </transitionToAuto>
</template>

<style lang="scss">
.footer_logo {
  width: auto;
  height: 1.8rem;
  margin-bottom: 0.5rem;
  opacity: 0.8;
}
#footer_wrap {
  a {
    color: #aaa;
    &:hover {
      color: #fff;
    }
  }
}
</style>

<script>
import { mapState } from 'vuex'

export default {
  computed: {
    ...mapState({
      LayoutStore: state => state.LayoutStore,
    }),
  },
}
</script>
