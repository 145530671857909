<template>
  <div :style="divStyle" class="designer-view-shape-wrap"></div>
</template>

<script>
export default {
  props: {
    element: Object,
    pixelsPerMm: Number,
  },
  computed: {
    divStyle() {
      let minSide = Math.min(this.element.position.height, this.element.position.width)
      let style = {
        backgroundColor: this.element.backcolor,
        borderRadius: this.element.shape === 'ellipse' ? '50%' : parseFloat(this.element.cornerRounding) / 100 * (minSide / 2) * this.pixelsPerMm + 'px',
      }
      return style
    },
  },
}
</script>
<style lang="scss" scoped>
.designer-view-shape-wrap {
  height: 100%;
}
</style>
