<template>
  <McForm
    ref="mcFormInstance"
    v-bind="$attrs"
    @reset="handleReset"
    @completed="e => $emit('completed', e)"
    :onSubmit="handleSubmit"
    :submiticon="isEditing ? 'save' : 'plus'"
    :title="title"
    :submittext="isEditing ? $t('general.Save') : $t('general.Create')"
  >
    <mc-form-input
      v-if="curData.type == 'giftcard'"
      name="headline"
      type="textarea"
      v-model="curData.headline"
      v-validate="'required'"
      :data-vv-as="$t('giftcards.Headline')"
      :label="$t('giftcards.Headline')"
      :placeholder="$t('giftcards.EnterHeadline')"
      key="giftcardform.headline"
      :errorHint="errors.first('headline')"
      :state="!errors.has('headline')"
      :icon="['far', 'heading']"
      :description="$t('giftcards.HeadlineDescription')"
    />

    <mc-form-input
      name="webshopName"
      type="textarea"
      v-model="curData.webshopName"
      :label="$t('giftcards.WebshopName.' + curData.type)"
      :placeholder="$t('giftcards.EnterWebshopName.' + curData.type, { headline: curData.headline })"
      key="giftcardform.webshopName"
      :errorHint="errors.first('webshopName')"
      :state="!errors.has('webshopName')"
      icon="signature"
      :description="$t('giftcards.WebshopNameDescription.' + curData.type)"
    />

    <mc-form-input
      v-if="curData.type == 'giftcard'"
      :icon="['fad', 'file-contract']"
      name="terms"
      type="textarea"
      v-validate="'required|min:30'"
      :data-vv-as="$t('giftcards.Terms')"
      :label="$t('giftcards.Terms')"
      v-model="curData.terms"
      :errorHint="errors.first('terms')"
      :state="!errors.has('terms')"
      key="giftcardform.terms"
      :placeholder="$t('giftcards.EnterTerms')"
      :description="$t('giftcards.TermsDescription')"
    />

    <mc-form-input
      v-if="curData.type == 'giftcard' && curData.supportsGiftcardDynamic1"
      :icon="['fad', 'file-contract']"
      name="dynamic1"
      type="textarea"
      :label="$t('giftcards.Dynamic1')"
      v-model="curData.dynamic1"
      key="giftcardform.dynamic1"
      :placeholder="$t('giftcards.EnterDynamic')"
      :description="$t('giftcards.DynamicDescription')"
    />

    <mc-form-input
      v-if="curData.type == 'giftcard' && curData.supportsGiftcardDynamic2"
      :icon="['fad', 'file-contract']"
      name="dynamic2"
      type="textarea"
      :label="$t('giftcards.Dynamic2')"
      v-model="curData.dynamic2"
      key="giftcardform.dynamic2"
      :placeholder="$t('giftcards.EnterDynamic')"
      :description="$t('giftcards.DynamicDescription')"
    />

    <div v-if="curData.type == 'giftcard'">
      <b-form-radio class="d-inline-block mr-4" v-model="isFreeAmount" :value="false">{{ $t('giftcards.DefinedAmount') }}</b-form-radio>
      <b-form-radio class="d-inline-block" v-model="isFreeAmount" :value="true">{{ $t('giftcards.FreeAmount') }}</b-form-radio>
    </div>

    <mcFormDualInputs
      :errorHint="errors.first('minFreeAmount') || errors.first('maxFreeAmount')"
      :state="!errors.has('minFreeAmount') && !errors.has('maxFreeAmount')"
      :description="$t('giftcards.FreeAmountDescription', { minAmount: niceCurrencyMaybeWithoutCents(minAmount) })"
      v-if="curData.type == 'giftcard' && isFreeAmount"
    >
      <template v-slot:input1>
        <mc-form-input
          :icon="['fad', 'money-bill-wave']"
          name="minFreeAmount"
          type="number"
          :min="minAmount"
          max="5000"
          step="5"
          v-validate="'required|decimal:2|min_value:' + minAmount"
          :data-vv-as="$t('giftcards.MinFreeAmount')"
          :label="$t('giftcards.MinFreeAmount')"
          v-model="curData.minFreeAmount"
          :placeholder="$t('giftcards.EnterMinFreeAmount')"
          :state="!errors.has('minFreeAmount')"
          key="giftcardform.minFreeAmount"
          :unit="$t('general.DKK')"
        />
      </template>
      <template v-slot:input2>
        <mc-form-input
          :icon="['fad', 'money-bill-wave']"
          name="maxFreeAmount"
          type="number"
          :min="curData.minFreeAmount"
          max="100000"
          step="5"
          v-validate="'required|decimal:2|min_value:' + curData.minFreeAmount"
          :data-vv-as="$t('giftcards.MaxFreeAmount')"
          :label="$t('giftcards.MaxFreeAmount')"
          v-model="curData.maxFreeAmount"
          :placeholder="$t('giftcards.EnterMaxFreeAmount')"
          :state="!errors.has('maxFreeAmount')"
          key="giftcardform.maxFreeAmount"
          :unit="$t('general.DKK')"
        />
      </template>
    </mcFormDualInputs>
    <mcFormDualInputs
      :errorHint="errors.first('defFreeAmount') || errors.first('stepFreeAmount')"
      :state="!errors.has('defFreeAmount') && !errors.has('stepFreeAmount')"
      :description="$t('giftcards.DefaultFreeAmountDescription')"
      v-if="curData.type == 'giftcard' && isFreeAmount"
    >
      <template v-slot:input1>
        <mc-form-input
          v-if="curData.type == 'giftcard' && isFreeAmount"
          :icon="['fad', 'money-bill-wave']"
          name="defFreeAmount"
          type="number"
          :min="curData.minFreeAmount"
          :max="curData.maxFreeAmount"
          step="5"
          v-validate="'required|decimal:2|min_value:' + curData.minFreeAmount + '|max_value:' + curData.maxFreeAmount"
          :data-vv-as="$t('giftcards.DefaultFreeAmount')"
          :label="$t('giftcards.DefaultFreeAmount')"
          v-model="curData.defFreeAmount"
          :placeholder="$t('giftcards.EnterDefaultFreeAmount')"
          :state="!errors.has('defFreeAmount')"
          key="giftcardform.defFreeAmount"
          :unit="$t('general.DKK')"
        />
      </template>
      <template v-slot:input2>
        <mc-form-select
          :icon="['fad', 'money-bill-wave']"
          name="stepFreeAmount"
          :label="$t('giftcards.StepFreeAmount')"
          v-model="curData.stepFreeAmount"
          :options="[{value: 1, label: '1'}, { value: 5, label: '5' }, { value: 10, label: '10' }, { value: 25, label: '25' }, { value: 50, label: '50' }, { value: 100, label: '100' }]"
          key="giftcardform.stepFreeAmount"
          :unit="$t('general.DKK')"
        />
      </template>
    </mcFormDualInputs>

    <mc-form-input
      v-if="curData.type == 'giftcard' && !isFreeAmount"
      :icon="['fad', 'money-bill-wave']"
      name="price"
      type="number"
      :min="minAmount"
      max="100000"
      step="5"
      v-validate="'required|decimal:2|min_value:' + minAmount"
      :data-vv-as="$t('giftcards.Price')"
      :label="$t('giftcards.Price')"
      v-model="curData.price"
      :placeholder="$t('giftcards.EnterPrice')"
      :errorHint="errors.first('price')"
      :state="!errors.has('price')"
      key="giftcardform.price"
      :description="$t('giftcards.PriceDescription', { minAmount: niceCurrencyMaybeWithoutCents(minAmount) })"
      :unit="$t('general.DKK')"
    />

    <!-- eslint-disable-next-line -->
    <McFormSelect
      v-if="curData.type == 'giftcard'"
      :label="$t('giftcards.Validity')"
      :icon="['fad', 'calendar-exclamation']"
      v-model="curData.validity"
      :options="validityOptions"
      :description="$t('giftcards.ValidityDescription')"
    />

    <mcFormDatePicker
      v-if="curData.type == 'giftcard' && curData.validity == -1"
      :label="$t('giftcards.StaticValidity')"
      :icon="['fad', 'calendar-exclamation']"
      v-model="curData.staticValidity"
      :description="$t('giftcards.StaticValidityDescription')"
    />

    <!-- eslint-disable-next-line -->
    <McFormImage
      name="file"
      :label="$t('giftcards.Image.' + curData.type)"
      :placeholder="$t('giftcards.PickImage.' + curData.type)"
      icon="image"
      v-model="curData.image"
      :description="$t('giftcards.ImageDescription.' + curData.type)"
    />

    <mc-form-input
      :icon="['fad', 'file-contract']"
      name="description"
      type="wysiwyg"
      buttons="most"
      :data-vv-as="$t('giftcards.Description.' + curData.type)"
      :label="$t('giftcards.Description.' + curData.type)"
      v-model="curData.description"
      :errorHint="errors.first('description')"
      :state="!errors.has('description')"
      key="giftcardform.description"
      :placeholder="$t('giftcards.EnterDescription.' + curData.type)"
      :description="$t('giftcards.DescriptionDescription.' + curData.type)"
    />

    <McFormSelect
      v-if="curData.type == 'giftcard' && designOptions.length > 0"
      :label="$t('giftcards.Design')"
      :icon="['fad', 'file-pdf']"
      v-model="curData.designId"
      :options="designOptions"
      :description="$t('giftcards.GiftcardDesignDescription')"
    />

    <div v-if="curData.type == 'giftcard' && designOptions.length > 0 && deliveryMethods.length > 1">
      <b-form-checkbox class="d-inline-block mr-4" v-model="hasSpecialDeliveryMethodDesigns">{{ $t('giftcards.DesignPerDeliveryMethod') }}</b-form-checkbox>
    </div>

    <div v-if="curData.type == 'giftcard' && designOptions.length > 0 && deliveryMethods.length > 1 && hasSpecialDeliveryMethodDesigns">
      <McFormSelect
        v-for="deliveryMethod in deliveryMethods"
        v-bind:key="deliveryMethod.id"
        :label="$t('giftcards.Design') + ' - ' + deliveryMethod.headline"
        :icon="['fad', 'file-pdf']"
        v-model="designIdPerDeliveryMethod[deliveryMethod.id]"
        :options="designOptionsPerDeliveryMethod"
      />
    </div>
    <!--pre>{{ designIdPerDeliveryMethod }}</pre-->
  </McForm>
</template>

<script>
import { mapActions } from 'vuex'
import { isEmptyObject } from '@/helpers/Misc'
import GiftcardService from '@/services/GiftcardService'
import { niceCurrencyMaybeWithoutCents } from '@/helpers/Formatting'

const defaultData = {
  id: 0,
  type: 'giftcard',
  headline: '',
  price: 500,
  minFreeAmount: 200,
  maxFreeAmount: 5000,
  defFreeAmount: 400,
  stepFreeAmount: 50,
  validity: 730,
  staticValidity: '0000-00-00',
  terms: '',
  dynamic1: '',
  dynamic2: '',
  supportsPersonalMessage: false,
  supportsRecipientName: false,
  supportsGiftcardDynamic1: false,
  supportsGiftcardDynamic2: false,
  webshopName: '',
  description: '',
  image: null,
  designId: -1,
  deliveryMethodDesigns: [],
}

export default {
  components: {},
  data() {
    return {
      curData: { ...defaultData },
      isEditing: false,
      isFreeAmount: false,
      hasSpecialDeliveryMethodDesigns: false,
      designIdPerDeliveryMethod: {},
    }
  },
  props: {
    value: Object,
    merchantId: Number,
    designs: Array,
    deliveryMethods: Array,
  },
  watch: {
    value(val) {
      this.$refs['mcFormInstance'].forceReset()

      defaultData.terms = this.$t('giftcards.TermsBeginning')

      let newVal = {}

      for (var field in defaultData) {
        //console.log(field, val.hasOwnProperty('minFreeAmountInCents'))
        if (field === 'price' && val.hasOwnProperty('priceInCents')) {
          newVal[field] = val.priceInCents / 100
        } else if (field === 'minFreeAmount' && val.hasOwnProperty('minFreeAmountInCents')) {
          newVal[field] = val.minFreeAmountInCents / 100
        } else if (field === 'maxFreeAmount' && val.hasOwnProperty('maxFreeAmountInCents')) {
          newVal[field] = val.maxFreeAmountInCents / 100
        } else if (field === 'defFreeAmount' && val.hasOwnProperty('defFreeAmountInCents')) {
          newVal[field] = val.defFreeAmountInCents / 100
        } else if (field === 'stepFreeAmount' && val.hasOwnProperty('stepFreeAmountInCents')) {
          newVal[field] = val.stepFreeAmountInCents / 100
        } else {
          newVal[field] = val.hasOwnProperty(field) ? val[field] : defaultData[field]
        }
      }

      var dpdm = {}
      this.hasSpecialDeliveryMethodDesigns = false
      if (newVal.hasOwnProperty('deliveryMethodDesigns')) {
        for (var i in newVal.deliveryMethodDesigns) {
          var v = newVal.deliveryMethodDesigns[i]
          if (v.designId > 0) {
            this.hasSpecialDeliveryMethodDesigns = true
            dpdm[v.deliveryMethodId] = v.designId
          }
        }
      }
      //console.log(dpdm)
      this.designIdPerDeliveryMethod = dpdm

      if (newVal.webshopName === newVal.headline) {
        newVal.webshopName = ''
      }

      this.curData = newVal
      this.isFreeAmount = newVal.price == 0
      if (!this.isFreeAmount) {
        newVal.minFreeAmount = this.minAmount
        newVal.maxFreeAmount = this.minAmount * 20
        newVal.defFreeAmount = this.minAmount * 2
        newVal.stepFreeAmount = 50
      }

      delete val.type
      //If empty object passed, we are not editing an existing object
      this.isEditing = !isEmptyObject(val)
    },
  },

  $_veeValidate: {
    validator: 'new', // give me my own validator scope.
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),

    handleReset() {
      this.curData = { ...defaultData }
    },

    handleSubmit(e) {
      let action = GiftcardService.create
      if (this.isEditing) {
        action = GiftcardService.update
      }
      let postData = { ...this.curData }
      if (this.isFreeAmount) {
        postData.priceInCents = 0
        postData.minFreeAmountInCents = 100 * this.curData.minFreeAmount
        postData.maxFreeAmountInCents = 100 * this.curData.maxFreeAmount
        postData.defFreeAmountInCents = 100 * this.curData.defFreeAmount
        postData.stepFreeAmountInCents = 100 * this.curData.stepFreeAmount
      } else {
        postData.priceInCents = 100 * this.curData.price
        postData.minFreeAmountInCents = 0
        postData.maxFreeAmountInCents = 0
        postData.defFreeAmountInCents = 0
        postData.stepFreeAmountInCents = 0
      }
      delete postData['price']
      delete postData['minFreeAmount']
      delete postData['maxFreeAmount']
      delete postData['defFreeAmount']
      delete postData['stepFreeAmount']

      var deliveryMethodDesigns = []
      if (this.hasSpecialDeliveryMethodDesigns) {
        for (var i in this.deliveryMethods) {
          var dm = this.deliveryMethods[i]
          if (this.designIdPerDeliveryMethod.hasOwnProperty(dm.id) && this.designIdPerDeliveryMethod[dm.id] > 0) {
            deliveryMethodDesigns.push({
              deliveryMethodId: dm.id,
              designId: this.designIdPerDeliveryMethod[dm.id],
            })
          }
        }
      }
      postData['deliveryMethodDesigns'] = deliveryMethodDesigns

      return action(this.merchantId, postData).then(
        data => {
          this.toastSuccess(this.$t('general.ChangesSaved'))
          return Promise.resolve(data)
        },
        error => {
          console.error('Error creating merchant', error)
          return Promise.reject(error)
        },
      )
    },
    niceCurrencyMaybeWithoutCents(amount) {
      return niceCurrencyMaybeWithoutCents(amount * 100)
    },
  },
  computed: {
    minAmount() {
      let merchant = this.$merchantInfo.fromId(this.merchantId)
      if (merchant) {
        return merchant.minAmount
      }
      return 295
    },
    validityOptions() {
      return [
        { value: -1, label: this.$t('giftcards.validityStatic') },
        { value: 365, label: this.$t('giftcards.validity365') },
        { value: 730, label: this.$t('giftcards.validity730') },
        { value: 1095, label: this.$t('giftcards.validity1095') },
      ]
    },
    designOptions() {
      let ret = []

      for (var i in this.designs) {
        ret.push({ value: this.designs[i].id, label: this.designs[i].title })
      }

      return ret
    },
    chosenDesign() {
      for (var i in this.designs) {
        if (this.curData.designId == this.designs[i].id) {
          return this.designs[i]
        }
      }
      return null
    },
    designOptionsPerDeliveryMethod() {
      let ret = []
      if (this.chosenDesign) {
        ret.push({ value: -1, label: 'Standard: ' + this.chosenDesign.title })
      }
      for (var i in this.designOptions) {
        ret.push(this.designOptions[i])
      }
      return ret
    },
    title() {
      if (this.curData.type == 'category') {
        return this.isEditing ? this.$t('giftcards.EditCategory') : this.$t('giftcards.CreateNewCategory')
      }
      return this.isEditing ? this.$t('giftcards.EditGiftcard') : this.$t('giftcards.CreateNewGiftcard')
    },
  },
}
</script>
