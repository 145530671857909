import { authorizedApiRequest } from '@/setup/ApiService'

export default {
  get: function (merchant) {
    return authorizedApiRequest(
      '/designs/' + merchant, //
      'GET',
    )
  },

  create: function (merchant, design) {
    return authorizedApiRequest(
      '/designs/' + merchant, //
      'POST',
      design,
    )
  },

  clone: function (merchant, design) {
    return authorizedApiRequest(
      '/designs/' + merchant + '/' + design.id + '/clone', //
      'POST',
    )
  },

  preview: function (merchant, design) {
    return authorizedApiRequest(
      '/designs/' + merchant + '/preview', //
      'POST',
      {
        design,
      },
    )
  },

  update: function (merchant, design) {
    return authorizedApiRequest(
      '/designs/' + merchant + '/' + design.id, //
      'PUT',
      design,
    )
  },

  delete: function (merchant, design) {
    return authorizedApiRequest(
      '/designs/' + merchant + '/' + design.id, //
      'DELETE',
    )
  },
}
