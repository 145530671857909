<template>
  <div :style="divStyle" class="designer-view-textarea-wrap">
    <div v-if="element.type === 'textarea'" ref="html_content">
      <div class="textarea__html_push" :style="'height:' + calculatedMarginTop + 'px'" />
      <div class="textarea__html_content" v-html="content" />
    </div>
    <DesignerViewValExp
      v-if="element.type === 'valexp'"
      :layout="element.layout"
      :element="element"
      :pixelsPerMm="pixelsPerMm"
      :value-label="'Værdi: '"
      :value-value="subTags('{VÆRDI}')"
      :code-label="'Gavekort-nr: '"
      :code-value="subTags('{GAVEKORTNR}')"
      :expiry-label="'Gyldigt til: '"
      :expiry-value="subTags('{UDLØB}')"
      :credit-label="subTags('Gavekort udbydes i samarbejde med danskegavekort.dk')"
    />
  </div>
</template>

<script>
import moment from 'moment-timezone'
import { dateLikePdf, currencyLikePdf } from '@/helpers/Formatting'
export default {
  data() {
    return {
      fontSizeCalculated: 10,
      calculatedMarginTop: 0,
    }
  },
  props: {
    giftcard: Object,
    merchant: Object,
    element: Object,
    pixelsPerMm: Number,
  },
  computed: {
    weightRegular() {
      return this.element.fontWeight
    },
    weightBold() {
      return this.element.fontWeightBold
    },
    fontSpacing() {
      return this.element.fontSpacing + 'em'
    },
    fontSpacingNegative() {
      return '-' + this.element.fontSpacing + 'em'
    },
    padding() {
      let padding = this.element.padding * this.pixelsPerMm
      if (!this.element.useBackground) {
        padding = 0
      }
      return padding
    },
    content() {
      return this.subTags(this.element.content)
    },
    divStyle() {
      let minSide = Math.min(this.element.position.height, this.element.position.width)
      let style = {
        borderRadius: parseFloat(this.element.cornerRounding) / 100 * (minSide / 2) * this.pixelsPerMm + 'px',
        fontSize: 3.527 * this.fontSizeCalculated + 'px',
        fontFamily: this.element.font,
        fontSynthesis: 'none',
        color: this.element.forecolor,
        textAlign: this.element.textAlign,
      }
      if (this.element.useBackground) {
        style.backgroundColor = this.element.backcolor
      }
      return style
    },
  },
  methods: {
    subTags(withTags) {
      if (!withTags) {
        return ''
      }
      let expiry = ''
      if (this.giftcard.validity == -1) {
        expiry = moment(this.giftcard.staticValidity)
      } else {
        expiry = moment().add(this.giftcard.validity, 'day')
      }
      return withTags //
        .replaceAll('{GAVEKORTNR}', this.merchant.prefix + '-999999')
        .replaceAll('{UDLØB}', dateLikePdf(expiry))
        .replaceAll('{UDLØB|d/m/y}', moment().add(this.giftcard.validity, 'day').format('D/M/YY'))
        .replaceAll('{VÆRDI}', currencyLikePdf(this.giftcard.exampleAmount))
        .replaceAll('{BETINGELSER}', this.giftcard.terms)
        .replaceAll('{OVERSKRIFT}', this.giftcard.headline)
        .replaceAll('{MODTAGER}', 'Bentemus')
    },
    recalculateAndRepeat(repeats) {
      this.recalculateFontSize()
      if (repeats > 0) {
        setTimeout(() => {
          this.recalculateAndRepeat(repeats - 1)
        }, 50)
      }
    },
    recalculateFontSize() {
      window.requestAnimationFrame(() => {
        if (this.element.type !== 'textarea') {
          this.fontSizeCalculated = this.element.fontSize
          return
        }
        this.fontSizeCalculated = this.textFillSize(this.$refs.html_content, this.element.fontSize, this.$refs.html_content.clientWidth, this.$refs.html_content.clientHeight)
      })
    },
    textFillSize(el, targetFontSize, targetWidth, targetHeight) {
      let clone = el.cloneNode(true)
      el.after(clone)
      clone.removeChild(clone.querySelector('.textarea__html_push'))
      clone.style.scrollX = 'scroll'
      clone.style.position = 'absolute'
      clone.style.top = '0px'
      clone.style.paddingTop = '0px'
      clone.style.left = '0px'
      clone.style.width = targetWidth + 'px'
      clone.style.height = 'auto'
      clone.style.clipPath = 'none'
      clone.style.lineHeight = '1.25'
      let fontSize = targetFontSize
      clone.style.fontSize = 3.527 * fontSize + 'px'
      //let its = 0
      while (this.isFontTooBig(clone, targetHeight, targetWidth) && fontSize > 2) {
        fontSize = fontSize / 1.2
        clone.style.fontSize = 3.527 * fontSize + 'px'
        //its++
      }
      while (!this.isFontTooBig(clone, targetHeight, targetWidth) && fontSize < targetFontSize) {
        fontSize = fontSize * 1.01
        clone.style.fontSize = 3.527 * fontSize + 'px'
        //its++
      }
      while (this.isFontTooBig(clone, targetHeight, targetWidth) && fontSize > 2) {
        fontSize = fontSize / 1.001
        clone.style.fontSize = 3.527 * fontSize + 'px'
        //its++
      }
      this.calculatedMarginTop = (targetHeight - clone.clientHeight) / 2

      //console.log(fontSize, clone.clientHeight)
      //console.log(this.calculatedMarginTop)
      clone.remove()
      return fontSize
    },
    isFontTooBig(clone, targetHeight, targetWidth) {
      return clone.clientHeight > targetHeight // || clone.scrollWidth > targetWidth
    },
  },
  mounted() {
    this.recalculateFontSize()
  },
  watch: {
    'element.font'() {
      this.recalculateAndRepeat(20)
    },
    'element.fontWeight'() {
      this.recalculateAndRepeat(20)
    },
    'element.fontWeightBold'() {
      this.recalculateAndRepeat(20)
    },
    'element.fontSpacing'() {
      this.recalculateAndRepeat(20)
    },
    element: {
      deep: true,
      handler: function () {
        this.recalculateFontSize()
      },
    },
  },
}
</script>
<style lang="scss" scoped>
.designer-view-textarea-wrap {
  line-height: 1.25em;
  font-weight: v-bind(weightRegular);
  letter-spacing: v-bind(fontSpacing);
  height: 100%;
  padding: v-bind('padding + "px"');
  & > div {
    width: 100%;
    height: 100%;
    position: relative;
    clip-path: v-bind('element.type === "valexp" ? "none" : "inset(-100vw -100vw 0 -100vw)"');
  }
  :deep(p) {
    margin: 0 0 1em;
    min-height: 0.2em;
  }
  :deep(strong, b) {
    font-weight: v-bind(weightBold);
  }
  .textarea__html_content {
    word-wrap: break-word;
    margin-right: v-bind(fontSpacingNegative);
  }
  .textarea__html_content > :last-child {
    margin-bottom: 0 !important;
  }
}
</style>
