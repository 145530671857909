<template>
  <div class="designer-element-position d-flex">
    <McFormInput
      type="number"
      size="sm"
      unit="mm"
      :max="210 + 100 - position.width"
      :min="-100"
      :step="1 / pixelsPerMm"
      :label="$t('general.Left')"
      :value="position.left"
      @input="val => setPosition('left', val)"
    />
    <McFormInput
      type="number"
      size="sm"
      unit="mm"
      :max="297 + 100 - position.height"
      :min="-100"
      :step="1 / pixelsPerMm"
      :label="$t('general.Top')"
      :value="position.top"
      @input="val => setPosition('top', val)"
    />
    <!-- prettier-ignore -->
    <McFormInput
      type="number"
      size="sm"
      unit="mm"
      :min="1"
      :max="210 + 200"
      :step="1 / pixelsPerMm"
      :label="$t('general.Width')"
      :value="position.width"
      @input="val => setPosition('width', val)"
    />
    <McFormInput
      type="number"
      size="sm"
      unit="mm"
      :min="1"
      :max="297 + 200"
      :step="1 / pixelsPerMm"
      :label="$t('general.Height')"
      :value="position.height"
      @input="val => setPosition('height', val)"
    />
    <McFormInput
      type="number"
      size="sm"
      unit="°"
      step="1"
      :label="$t('general.Rotation')"
      :value="position.rotation"
      @input="val => setPosition('rotation', val)"
      @blur="e => setPosition('rotation', e.target.value, false)"
    />
  </div>
</template>

<script>
export default {
  props: {
    position: Object,
    pixelsPerMm: Number,
  },
  methods: {
    setPosition(which, value, soft = true) {
      if (which == 'rotation') {
        if (soft) {
          value = parseInt(Math.round(value))
        } else {
          value = parseInt(Math.round(value)) % 360
        }
      } else {
        value = Math.round(value * this.pixelsPerMm) / this.pixelsPerMm
      }
      let newPosition = { ...this.position }
      newPosition[which] = value
      this.$emit('moved', newPosition)
    },
  },
}
</script>

<style lang="scss">
.designer-element-position {
  justify-content: space-between;
  > .form-group {
    margin-right: 1%;
    width: 21%;
    &:last-child {
      margin-left: 1%;
      margin-right: 0;
      flex-basis: 1;
      width: 11%;
    }
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type='number'] {
    -moz-appearance: textfield;
  }

  input[type='number'] {
    text-align: right;
    padding-left: 0;
    padding-right: 4px;
  }
  .input-group-append .input-group-text {
    text-align: left;
    padding-left: 4px;
    padding-right: 4px;
  }
}
</style>
