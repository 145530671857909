<template>
  <McLayoutDefault :heading="$t('headings.Designs')">
    <b-card :title="$t('permissions.LackingPermissions')" class="mb-2" v-if="!$perms.isAdministrator() && !$perms.hasMerchantsWithPermission('manageCertificates')">
      <p>{{ $t('permissions.LackingPermissionsLonger') }}</p>
    </b-card>
    <b-row v-else>
      <b-col md="4" cols="12">
        <b-card class="mb-2">
          <p>{{ $t('designs.PageDescription') }}</p>
          <MerchantSelector @merchantSelected="m => (merchant = m)" permission="manageCertificates" v-model="merchantId" />
        </b-card>
      </b-col>
      <b-col md="8" cols="12">
        <b-card v-for="design in designs" v-bind:key="design.id" class="p-1 mb-2">
          {{ design.title }}<br>
          <small>{{ design.usedOn }}</small>
          <b-button-group class="float-right">
            <mc-button variant="success" icon="edit" @click="editDesign(design)"></mc-button>
            <mc-button variant="primary" icon="clone" @click="cloneDesign(design)" />
            <mc-button variant="danger" icon="trash" @click="deleteDesign(design)"></mc-button>
          </b-button-group>
        </b-card>

        <div class="text-center my-3">
          <!-- eslint-disable-next-line -->
          <McButton
            @click="editDesign({})"
            variant="primary"
            icon="plus"
            :text="$t('designs.CreateNewDesign')"
            class="mr-2"
          />

        </div>
      </b-col>
    </b-row>
    <fullscreen-editor ref="designEditor" :beforeClose="closeDesignerIfUnchanged">
      <Designer2024 ref="designEditorInner" :merchantId="merchantId" :merchant="merchant" :allCards="cards" :design="editingDesign" @saved="refreshDesigns" @cancel="$refs['designEditor'].hide()" />
    </fullscreen-editor>
  </McLayoutDefault>
</template>

<script>
import { mapActions } from 'vuex'
import { FormatError } from '@/helpers/ErrorFormatting'
import GiftcardService from '@/services/GiftcardService'
import DesignsService from '@/services/DesignsService'

export default {
  data() {
    return {
      cards: [],
      designs: [],
      merchantId: null,
      merchant: null,
      editingDesign: {},
      merchantDomains: [],
    }
  },
  watch: {
    merchantId() {
      this.refreshDesigns()
    },
  },
  methods: {
    ...mapActions('ToastStore', [
      'toastSuccess', //
    ]),
    editDesign(design) {
      this.editingDesign = design
      this.$refs['designEditor'].show().then(() => {
        this.$refs['designEditorInner'].setDesign(design)
      })
    },
    cloneDesign(design) {
      DesignsService.clone(this.merchantId, design).finally(() => {
        this.refreshDesigns()
      })
    },
    deleteDesign(design) {
      if (confirm(this.$t('designs.ConfirmDelete', { name: design.title }))) {
        DesignsService.delete(this.merchantId, design)
          .catch(error => {
            alert(FormatError(error))
          })
          .finally(() => {
            this.refreshDesigns()
          })
      }
    },
    closeDesignerIfUnchanged() {
      this.$refs['designEditorInner'].closeIfUnchanged()
    },
    refreshDesigns() {
      DesignsService.get(this.merchantId).then(
        data => {
          this.designs = data
        },
        error => {
          console.error('Error getting designs', error)
          return Promise.reject(error)
        },
      )
      GiftcardService.get(this.merchantId).then(
        data => {
          this.cards = data
        },
        error => {
          console.error('Error getting gift cards', error)
          return Promise.reject(error)
        },
      )
    },
  },
}
</script>
