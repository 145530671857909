<template>
  <div v-if="!this.deleted" :data-card-id="card.id" :class="'gift-card-listitem-wrapper card mb-2 ' + (card.published ? 'published' : 'draft')">
    <div class="card-body py-0 pr-2">
      <div class="my-2 clearfix">
        <div class="clearfix">
          <!--pre>{{ card }}</pre-->
          <div class="item-cols">
            <div class="item-col item-preview">
              <div class="item-image" v-if="card.image.url" :style="card.image.url ? 'background-image: url(' + card.image.url + '/240x180)' : ''"></div>
            </div>
            <div class="item-col item-preview px-2 limitheight">
              <p>
                <b-badge v-if="card.type === 'giftcard' && card.priceInCents > 0" class="float-right m-1">{{ displayPrice }}</b-badge>
                <b-badge v-if="card.type === 'giftcard' && card.priceInCents == 0" class="float-right m-1">{{ displayFreeAmount }}</b-badge>
                <strong v-html="card.webshopName.replaceAll('\n', '<br>')"></strong>
              </p>
              <div style="font-size: 0.8em" v-html="card.description"></div>
            </div>
            <div class="item-col text-right">
              <label style="display: block">
                {{ card.published ? $t('giftcards.Published') : $t('giftcards.Draft') }}
                <b-form-checkbox variant="danger" v-model="checked" switch style="display: inline-block" />
              </label>
              <b-dropdown :disabled="busy" right :text="$t('giftcards.Actions')" variant="outline-primary" class="mt-2">
                <b-dropdown-item v-if="card.type == 'giftcard'" @click.stop="" target="_blank" :href="card.link"><McIcon :icon="['far', 'eye']" />{{ $t('giftcards.ViewExample') }} </b-dropdown-item>
                <b-dropdown-item href="#" @click="$emit('edit-giftcard', card)"><McIcon icon="edit" />{{ $t('giftcards.Edit') }} </b-dropdown-item>
                <b-dropdown-item href="#" @click="cloneGiftcard"><McIcon icon="clone" />{{ $t('giftcards.Clone') }} </b-dropdown-item>
                <b-dropdown-item v-if="card.published" href="#" @click="showEmbedCode"><McIcon :icon="['far', 'code']" />{{ $t('giftcards.EmbedCode') }} </b-dropdown-item>
                <b-dropdown-item href="#" variant="danger" @click="deleteGiftcard"><McIcon icon="trash" />{{ $t('giftcards.Delete') }} </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
      </div>
      <GiftCardList
        v-if="card.type === 'category'"
        class="my-2 category-sub-list"
        :data-parent-card-id="card.id"
        :cards="card.children"
        :merchantId="merchantId"
        :merchant="merchant"
        @edit-giftcard="card => $emit('edit-giftcard', card)"
        @edit-design="card => $emit('edit-design', card)"
        @refresh-cards="refreshCards"
        @sort-end="e => $emit('sort-end', e)"
      />
    </div>
    <b-modal :id="'embedCode-' + card.id" :title="$t('giftcards.EmbedCode')" ok-only>
      <div v-html="$t('giftcards.EmbedCodeDescription')" />
      <b-tabs class="mt-3">
        <b-tab title="WordPress">
          <pre @click.prevent="e => e.target.ownerDocument?.getSelection()?.selectAllChildren(e.target)" class="wpEmbedCode" v-html="shortTag" />
        </b-tab>
        <b-tab :title="$t('giftcards.EmbedCodeOther')">
          <pre @click.prevent="e => e.target.ownerDocument?.getSelection()?.selectAllChildren(e.target)" class="jsEmbedCode">{{ scriptTag }}</pre>
        </b-tab>
      </b-tabs>
    </b-modal>
  </div>
</template>

<script>
import GiftcardService from '@/services/GiftcardService'
import { niceCurrencyMaybeWithoutCents } from '@/helpers/Formatting'
import { jsEmbedCode, wpEmbedCode } from '@/helpers/EmbedCode'

export default {
  props: {
    card: Object,
    merchant: Object,
    merchantId: Number,
  },
  data() {
    return {
      cloning: false,
      deleting: false,
      deleted: false,
      publishing: false,
    }
  },
  computed: {
    busy() {
      return this.cloning || this.deleting || this.publishing || this.deleted
    },
    displayPrice() {
      return niceCurrencyMaybeWithoutCents(this.card.priceInCents)
    },
    displayFreeAmount() {
      return this.$t('giftcards.FreeAmountColon', { range: niceCurrencyMaybeWithoutCents(this.card.minFreeAmountInCents) + ' - ' + niceCurrencyMaybeWithoutCents(this.card.maxFreeAmountInCents) })
    },
    checked: {
      get() {
        return this.card.published
      },
      set(val) {
        this.publishing = true
        this.card.published = val
        GiftcardService.update(this.merchantId, {
          id: this.card.id,
          published: this.card.published ? 1 : 0,
        }).finally(() => {
          this.publishing = false
        })
      },
    },
    scriptTag() {
      return jsEmbedCode(this.merchantId, this.card.id)
    },
    shortTag() {
      return wpEmbedCode(this.card.id)
    },
  },
  methods: {
    refreshCards() {
      this.$emit('refresh-cards')
    },
    cloneGiftcard() {
      this.cloning = true
      GiftcardService.clone(this.merchantId, this.card).finally(() => {
        this.cloning = false
        this.refreshCards()
      })
    },
    showEmbedCode() {
      this.$bvModal.show('embedCode-' + this.card.id)
    },
    deleteGiftcard() {
      this.deleting = true
      if (confirm(this.$t('giftcards.ConfirmDelete', { name: this.card.webshopName }))) {
        GiftcardService.delete(this.merchantId, this.card).finally(() => {
          this.deleting = false
          this.deleted = true
          this.refreshCards()
        })
      }
    },
  },
}
</script>

<style lang="scss">
.category-sub-list:empty {
  background: #eee;
  border-radius: 3px;
}
.gift-card-listitem-wrapper {
  border-width: 2px;
  .gift-card-listitem-wrapper {
    border-width: 1px;
  }
  &:hover {
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  }
  &.draft {
    border-style: dashed;
    .gift-card-listitem-wrapper {
      border-style: dashed;
    }
    .item-preview {
      opacity: 0.6;
    }
  }

  .item-image {
    width: 128px;
    height: 96px;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 3px;
    display: inline-block;
    vertical-align: text-top;
  }
  .item-cols {
    width: 100%;
    display: flex;
    .item-col:nth-child(2) {
      flex-grow: 1;
    }
  }
  .limitheight {
    max-height: 100px;
    overflow: hidden;
    position: relative;
    &:after {
      position: absolute;
      top: 60px;
      left: 0;
      right: 0;
      content: '';
      background: linear-gradient(rgba(255, 255, 255, 0) 0%, #fff 100%);
      height: 40px;
    }
  }
}
.closeFullscreenModal {
  position: fixed;
  bottom: 10px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 99;
}
.jsEmbedCode,
.wpEmbedCode {
  width: 100%;
  border: 1px solid #ddd;
  border-top: none;
  background: #fff;
  padding: 1em;  
}
</style>
